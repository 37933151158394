<template>
  <!-- Add Message Modal -->
  <b-modal id="ModalChatEvent" no-fade size="lg" title="Mensagem" ok-title="Fechar" ok-only @shown="handleOpen" @hide="handleClose" @close="handleClose">
    <b-row v-if="loading" class="chat-events__loader">
      <b-col md="12">
        <transition name="fade">
          <div class="chat-events__loader-wrapper">
            <i class="chat-events__loader-icon fa fa-spin fa-cog" />
          </div>
        </transition>
      </b-col>
    </b-row>
    <b-row class="chat-events__list" :style="{ 'max-height': `9999px` }">
      <b-col v-if="message" md="12" class="mt-4 mb-5">
        <span v-html="message"> </span>
      </b-col>
    </b-row>
  </b-modal>
</template>

<style lang="scss" scoped></style>

<script>
/* eslint-disable vue/prop-name-casing */
import COMUNICATION_ARCHIVED from '@graphql/chat-bot/queries/get-communication-archived.gql';

export default {
  name: 'ModalChatEvent',
  props: {
    messageId: {
      type: String,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      block_ui: false,
      loading: false,
      message: [],
      hasErrorMessage: false,
      idMessage: '',
    };
  },
  methods: {
    async handleOpen() {
      this.idMessage = this.messageId;
      await this.getCommunication();
    },
    handleClose() {
      this.block_ui = false;
      this.loading = false;
      this.idMessage = '';
      this.message = '';
      this.$emit('clearMessaId');
      this.$root.$emit('bv::hide::modal', 'ModalChatEvent');
    },
    async getCommunication() {
      this.block_ui = true;
      this.message = '';
      this.loading = true;
      window.analytics.track('COMUNICATION_ARCHIVED', { messageId: this.idMessage });

      await this.$apollo
        .query({
          query: COMUNICATION_ARCHIVED,
          variables: {
            filters: {
              messageId: this.idMessage,
            },
          },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          this.message = data.supportCommunicationArchived.items[0].body;
        })
        .catch(err => {
          this.message = `Error: ${err.message || 'generic error'}`;
          window.analytics.track('COMUNICATION_ARCHIVED_ERROR', { messageId: this.idMessage });
          this.hasErrorMessage = true;
        });

      this.block_ui = false;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/components/chat-events.scss';
</style>
