<template>
  <b-container fluid class="chat p-0">
    <b-row
      v-if="loading"
      class="chat-events__loader"
    >
      <b-col md="12">
        <transition name="fade">
          <div class="chat-events__loader-wrapper">
            <i class="chat-events__loader-icon fa fa-spin fa-cog" />
          </div>
        </transition>
      </b-col>
    </b-row>

    <b-row
      v-if="!loading && (!hasTickets || hasErrorTicket)"
      class="chat-events__result-messages"
    >
      <b-col
        md="12"
        class="chat-events__result-message"
      >
        {{ $t('chat.messages.noItems') }}
      </b-col>
    </b-row>

    <b-row
      v-else
      class="chat-events__list"
      :style="{ 'max-height': `9999px` }"
    >
      <b-col md="12" class="mt-4 mb-5">
        <button type="button" class="btn btn-danger btn-block" @click="getCommunication()">BUSCAR MAIS</button>
      </b-col>
      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col">
            Visualizar
          </th>
          <th scope="col">
            Enviado
          </th>
          <th scope="col">
           Assunto
          </th>
          <th scope="col">
            Email
          </th>
          <th scope="col">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(communication, index) in communications"
            :key="index"
        >
          <td height>
            <button type="button" class="btn btn-default" aria-label="Left Align"
                    @click="showMessage(communication.id)"
            >
              <span class="fa fa-eye fa-lg" aria-hidden="true"></span>
            </button>
          </td>
          <td>
             {{ formatDate(communication.created) }}
          </td>
          <td>
            {{ communication.subject }}
          </td>
          <td>
            {{ communication.recipient }}
          </td>
          <td>
            <span v-if="
            communication.metrics.sent
            && !communication.metrics.opened
          " class="badge badge-pill badge-success">{{ getStatus(communication.metrics) }}</span>
            <span v-else-if="
            communication.metrics.sent
            && communication.metrics.opened
            " class="badge badge-pill badge-primary">{{ getStatus(communication.metrics) }}</span>
            <span v-else-if="communication.metrics.failed" class="badge badge-pill badge-danger">{{ getStatus(communication.metrics) }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </b-row>

    <b-row class="chat-events__pagination">
      <b-col md="12" class="mt-4 mb-5">
        <button type="button" class="btn btn-danger btn-block" @click="getCommunication()">BUSCAR MAIS</button>
      </b-col>
    </b-row>
    <modal-chat-event
        :message-id="messageId"
        @clearMessaId="messageId=''"
    />
  </b-container>
</template>

<script>
import COMUNICATION_GET from '@graphql/chat-bot/queries/get-communication.gql';
import { formatDateTimeZone } from '@utils/formatters/date';
import ModalChatEvent from '@components/modals/chat-events';

export default {
  name: 'ChatCommunication',
  async mounted() {
    this.block_ui = true
    await this.getCommunication()
  },
  components: {
    ModalChatEvent,
  },
  data () {
    return {
      block_ui: true,
      loading: false,
      loadingTicket: false,
      communications: [],
      statusCommunication: '',
      hasTickets: false,
      hasErrorTicket: false,
      activeTicket: '',
      loadingMessage: false,
      loadedMessage: false,
      hasMessages: false,
      next: undefined,
      rowFiltered: {},
      messageId: '',
    };
  },
  computed: {
    communicationFilter () {
      return {
        "customerId": this.$route.params.id,
        "next": this.next,
      }
    },
  },

  methods: {
    showMessage(value) {
      if (value) {
        this.messageId = value;
        this.$root.$emit('bv::show::modal', 'ModalChatEvent');
      }
    },
    async getCommunication () {

      this.block_ui = true
      this.hasTickets = false
      this.communications = []
      this.communicationsMsgs = []
      this.loading = true
      this.loadingTicket = false
      this.hasErrorTicket = false
      this.changedPerPage = false


      await this.$apollo.query({
        query: COMUNICATION_GET,
        variables: {
          "filters": this.communicationFilter,
          "next": this.next ? this.next : undefined,
        }
      })
      .then(({ data }) => {
        let responseCommunication = data.supportCommunication.items
        this.next = data.supportCommunication.next;

        if (responseCommunication.length) {
          this.rowFiltered = {
            ...this.rowFiltered,
            ...responseCommunication.filter((row) => {
              return row?.type === 'email';
            })
          }

          this.hasTickets = true
          this.communications = (this.rowFiltered)
        } else {
          this.hasTickets = false
          this.resetFilter()
        }
      })
      .catch(() => {
        this.hasErrorTicket = true
      })

      this.block_ui = false
      this.loading = false
      this.loadingTicket = true
    },

    getStatus(metrics) {
      let status;
      if (metrics?.sent) {
        status = 'enviado';
      }
      if (metrics?.delivered) {
        status = 'entregue';
      }
      if (metrics?.opened) {
        status = 'aberto';
      }

      if (metrics?.failed) {
        status = 'Falhou';
      }

      return status;
    },

    formatDate (value) {
      const timestampInMillisec = new Date(value * 1000)
      return formatDateTimeZone(timestampInMillisec, 'DD/MM/YYYY HH:mm:ss')
    },

    async changePage () {
      await this.getCommunication()
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/components/chat-events.scss';
</style>
