var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "ModalChatEvent",
        "no-fade": "",
        size: "lg",
        title: "Mensagem",
        "ok-title": "Fechar",
        "ok-only": "",
      },
      on: {
        shown: _vm.handleOpen,
        hide: _vm.handleClose,
        close: _vm.handleClose,
      },
    },
    [
      _vm.loading
        ? _c(
            "b-row",
            { staticClass: "chat-events__loader" },
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c("div", { staticClass: "chat-events__loader-wrapper" }, [
                      _c("i", {
                        staticClass:
                          "chat-events__loader-icon fa fa-spin fa-cog",
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "chat-events__list", style: { "max-height": `9999px` } },
        [
          _vm.message
            ? _c("b-col", { staticClass: "mt-4 mb-5", attrs: { md: "12" } }, [
                _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }