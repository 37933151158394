import { render, staticRenderFns } from "./chat-events.vue?vue&type=template&id=0b42f340&scoped=true&"
import script from "./chat-events.vue?vue&type=script&lang=js&"
export * from "./chat-events.vue?vue&type=script&lang=js&"
import style0 from "./chat-events.vue?vue&type=style&index=0&id=0b42f340&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b42f340",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/seed/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0b42f340')) {
      api.createRecord('0b42f340', component.options)
    } else {
      api.reload('0b42f340', component.options)
    }
    module.hot.accept("./chat-events.vue?vue&type=template&id=0b42f340&scoped=true&", function () {
      api.rerender('0b42f340', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/drivers/chat-events.vue"
export default component.exports