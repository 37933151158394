var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "chat p-0", attrs: { fluid: "" } },
    [
      _vm.loading
        ? _c(
            "b-row",
            { staticClass: "chat-events__loader" },
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c("div", { staticClass: "chat-events__loader-wrapper" }, [
                      _c("i", {
                        staticClass:
                          "chat-events__loader-icon fa fa-spin fa-cog",
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && (!_vm.hasTickets || _vm.hasErrorTicket)
        ? _c(
            "b-row",
            { staticClass: "chat-events__result-messages" },
            [
              _c(
                "b-col",
                {
                  staticClass: "chat-events__result-message",
                  attrs: { md: "12" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("chat.messages.noItems")) + " ")]
              ),
            ],
            1
          )
        : _c(
            "b-row",
            {
              staticClass: "chat-events__list",
              style: { "max-height": `9999px` },
            },
            [
              _c("b-col", { staticClass: "mt-4 mb-5", attrs: { md: "12" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger btn-block",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.getCommunication()
                      },
                    },
                  },
                  [_vm._v("BUSCAR MAIS")]
                ),
              ]),
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" Visualizar "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" Enviado "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" Assunto "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" Email ")]),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" Status ")]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.communications, function (communication, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { attrs: { height: "" } }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              "aria-label": "Left Align",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showMessage(communication.id)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "fa fa-eye fa-lg",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatDate(communication.created)) +
                            " "
                        ),
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(communication.subject) + " "),
                      ]),
                      _c("td", [
                        _vm._v(" " + _vm._s(communication.recipient) + " "),
                      ]),
                      _c("td", [
                        communication.metrics.sent &&
                        !communication.metrics.opened
                          ? _c(
                              "span",
                              { staticClass: "badge badge-pill badge-success" },
                              [
                                _vm._v(
                                  _vm._s(_vm.getStatus(communication.metrics))
                                ),
                              ]
                            )
                          : communication.metrics.sent &&
                            communication.metrics.opened
                          ? _c(
                              "span",
                              { staticClass: "badge badge-pill badge-primary" },
                              [
                                _vm._v(
                                  _vm._s(_vm.getStatus(communication.metrics))
                                ),
                              ]
                            )
                          : communication.metrics.failed
                          ? _c(
                              "span",
                              { staticClass: "badge badge-pill badge-danger" },
                              [
                                _vm._v(
                                  _vm._s(_vm.getStatus(communication.metrics))
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
      _c(
        "b-row",
        { staticClass: "chat-events__pagination" },
        [
          _c("b-col", { staticClass: "mt-4 mb-5", attrs: { md: "12" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger btn-block",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.getCommunication()
                  },
                },
              },
              [_vm._v("BUSCAR MAIS")]
            ),
          ]),
        ],
        1
      ),
      _c("modal-chat-event", {
        attrs: { "message-id": _vm.messageId },
        on: {
          clearMessaId: function ($event) {
            _vm.messageId = ""
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }